<template>
  <div>
    <div
      v-if="Object.keys(agency).length === 0"
      class="d-flex justify-content-center mb-1"
    >
      <b-spinner label="Loading..." />
    </div>
    <div v-else>
      <breadcrumbs
        :breadcrumb-items="breadcrumbItems"
      />
      <UserToolbar
        v-if="account && Object.keys(account).length !== 0"
        :account="account"
      />
      <b-form @submit.prevent>
        <validation-observer ref="validationAgencyRules">
          <b-tabs
            pills
          >
            <b-tab active>
              <!-- title -->
              <template #title>
                <feather-icon
                  icon="SettingsIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">{{ $t('Settings') }}</span>
              </template>
              <b-card>
                <div class="head-card">
                  <div class="head-card-top">
                    <h4>{{ $t('Main settings') }}</h4>
                    <feather-icon
                      v-b-tooltip.hover.top="$t('Close')"
                      icon="XIcon"
                      size="18"
                      class="toggle-close cursor-pointer"
                      @click="$router.go(-1)"
                    />
                  </div>
                  <hr>
                </div>
                <b-row>
                  <b-col md="12">
                    <b-form-group
                      :label="$t('partners.title')"
                      label-for="i-name"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="name"
                        rules="required"
                      >
                        <b-input-group
                          class="input-group-merge"
                          :class="errors.length == 0 || 'is-invalid'"
                        >
                          <b-input-group-prepend is-text>
                            <feather-icon icon="LayoutIcon" />
                          </b-input-group-prepend>
                          <b-form-input
                            id="i-name"
                            v-model="agency.title"
                            :placeholder="$t('partners.title')"
                            :state="errors.length > 0 ? false:null"
                            autocomplete="name"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    md="12"
                    class="d-flex align-items-center justify-content-end mt-1"
                  >
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-secondary"
                      class="mr-0 mr-sm-1"
                      @click="$router.go(-1)"
                    >
                      <feather-icon
                        icon="ChevronLeftIcon"
                        class="mr-50"
                      />
                      <span class="align-middle">{{ $t('form.back') }}</span>
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      @click="agencyCreate"
                    >
                      <span class="align-middle">{{ $t('form.save') }}</span>
                      <feather-icon
                        icon="ChevronRightIcon"
                        class="ml-50"
                      />
                    </b-button>
                  </b-col>
                </b-row>
              </b-card>
            </b-tab>
            <!--/ general tab -->
          </b-tabs>
        </validation-observer>
      </b-form>
    </div>
  </div>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import {
  BRow,
  BCol,
  BTabs,
  BTab,
  BCard,
  BForm,
  BFormGroup,
  // BInputGroupAppend,
  BInputGroupPrepend,
  BInputGroup,
  BFormInput,
  BSpinner,
  VBTooltip,
  BButton,
} from 'bootstrap-vue'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import {
  required,
  integer,
  min,
} from '@validations'
import Breadcrumbs from '@/views/ui/breadcrumbs/Breadcrumbs.vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import UserToolbar from '@/views/dashboard/users/UserToolbar.vue'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BRow,
    BCol,
    BTabs,
    BTab,
    BCard,
    Breadcrumbs,
    BForm,
    BFormGroup,
    // BInputGroupAppend,
    BInputGroupPrepend,
    BInputGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
    BButton,
    UserToolbar,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      min,
      required,
      account: {},
      authUserData: getUserData(),
      integer,
      agency: {
        title: '',
        image: null,
        owner: '',
        created_at: null,
        members: [],
      },
      breadcrumbItems: [],
    }
  },
  created() {
    this.getUserData()
  },
  methods: {
    async getUserData() {
      const responseData = await useJwt.getUser(this.$route.params.userId)
      this.account = responseData.data.account || {}
      this.setBreabcrumbs()
    },
    agencyCreate() {
      this.$refs.validationAgencyRules.validate().then(success => {
        if (success) {
          useJwt.createAgency({ agency: { ...this.agency, owner: this.$route.params.userId } })
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Agency created',
                  icon: 'EditIcon',
                  variant: 'success',
                },
              })
              this.$router.push(`/users/${this.$route.params.userId}/agencies`)
            })
        }
      })
    },
    async setBreabcrumbs() {
      this.breadcrumbItems = [
        {
          text: this.$t('page.menu.users'),
          to: '/users/',
        },
        {
          text: this.account.name,
          to: `/users/${this.agency.owner}`,
        },
        {
          text: this.$t('Partners'),
          to: `/users/${this.agency.owner}/agencies`,
        },
        {
          text: this.$t('partners.create'),
          active: true,
        },
      ]
    },
  },
}
</script>
